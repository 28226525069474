import React from 'react';
import { Link } from 'react-router-dom';
import hybridMotor from '../assets/eScooterMotor.jpg';
import "../pages/Hybrid.css";


function Hybrid() {
    return (
        <section className='hybridContainer' id='hybridScooter'>
            <div className='hybridKit'>
                <div>Convert any of your fuel Scooter into <strong>Hybrid Scooter</strong></div>

                <div style={{ marginTop: "10px" }}>(works with petrol and electric alternatively).</div>
                <div style={{ marginTop: "10px" }}>or to a Electric Scooter with Man Engineered Hybrid Kit / Convertion Kit
                    Delivering huge cost savings and stress free performance.
                </div>

                <div style={{ marginTop: "10px" }}>The Hybrid Kit comes with A Battery Pack and A Electric Motor and A Controller</div>
            </div>

            <Link to='/HybridMotor' className='hybridPageButton' >know more</Link>
            <img src={hybridMotor} alt='banner' className='hybridContainerimg' />

        </section>
    )
}

export default Hybrid;