import React from 'react';
import '../knowMore/PolyCast.css';
import polyImg from '../images/polyurethane-casting.jpg';

function PolyCast() {
    return (
        <div className='polyContainer'>
            <div className='blank'></div>
            <h2 className='polyHeader'>POLYEURETHANE CASTING</h2>
            <div>
                We innovate and manufacture Polyurethane Linings of international standards. Polyurethane
                Linings are suitable for protection of composite, steel bucket wheels of mining excavators.
                These linings are especially designed to in-line with bucket wheel inner surface to enable them
                to transfer load instantly also prevent damage from mechanical forces and chemical exposure.

            </div>


            <img className='polyImg' src={polyImg} alt='banner' />
        </div>
    );
};

export default PolyCast;