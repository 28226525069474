import React from 'react';
import '../knowMore/MechSeal.css';
import pdfIcn from '../images/pdfIcn.jfif';
import MGGpdf from '../documents/Corrugated-Metal-Graphite-Gasket.pdf';
import DJGpdf from '../documents/Double-Jacketed-Gasket.pdf';
import KPGpdf from '../documents/Kammprofile-Gaskets.pdf';
import RJGpdf from '../documents/Ring-Joint-Gaskets.pdf';
import SWGpdf from '../documents/Spiral-Wound-Gaskets.pdf';

function MechOIlSeal() {

    function forMGGpdf() {
        window.open(MGGpdf);
    };

    function forDJGpdf() {
        window.open(DJGpdf);
    };

    function forKPGpdf() {
        window.open(KPGpdf);
    };

    function forRJGpdf() {
        window.open(RJGpdf);
    };

    function forSWGpdf() {
        window.open(SWGpdf);
    };


    return (
        <div className='MSealContainer'>
            <div className='blank'></div>
            <h2 className='MSealHeader'>Mechanical seals</h2>
            <div>We are the manufacturer and supplier of cost effective
                Metal seal products.</div>

            <div className='gasketsBtn'>
                <button className='imgBtn' onClick={forMGGpdf}>
                    <img className='gasketsImg' src={pdfIcn} alt='banner' />
                    <p>Corrugated-Metal-Graphite-Gasket</p>
                </button>

                <button className='imgBtn' onClick={forDJGpdf}>
                    <img className='gasketsImg' src={pdfIcn} alt='banner' />
                    <p>Double-Jacketed-Gasket</p>
                </button>

                <button className='imgBtn' onClick={forKPGpdf}>
                    <img className='gasketsImg' src={pdfIcn} alt='banner' />
                    <p>Kammprofile-Gaskets</p>
                </button>
                <button className='imgBtn' onClick={forRJGpdf}>
                    <img className='gasketsImg' src={pdfIcn} alt='banner' />
                    <p>Ring-Joint-Gaskets</p>
                </button>
                <button className='imgBtn' onClick={forSWGpdf}>
                    <img className='gasketsImg' src={pdfIcn} alt='banner' />
                    <p>Spiral-Wound-Gaskets</p>
                </button>
            </div>
        </div>
    );
};

export default MechOIlSeal;