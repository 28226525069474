import React from 'react';

function Careers() {
  return (
    <div className='careerContainer'>
      <div className='blank'></div>
      <h2>updating soon...</h2>
      </div>
  )
}

export default Careers