import { useState, useEffect } from "react";
import logo from '../src/assets/manengsys-logo.png'
import '../src/Preloader.css';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Home from '../src/pages/Home';
import Services from '../src/pages/Services';
import Contact from '../src/pages/Contact';
import About from '../src/pages/About';
import EvSpeedBike from "../src/knowMore/EvSpeedBike";
import Aerospace from "../src/knowMore/Aerospace";
import CadCam from "../src/knowMore/CadCam";
import PolyCast from "../src/knowMore/PolyCast";
import IMLtooling from "../src/knowMore/IMLtooling";
import News from '../src/footerPages/News';
import Careers from '../src/footerPages/Careers';
import Gaskets from "../src/knowMore/Gaskets";
import MechSeal from "../src/knowMore/MechSeal";
import OilField from "../src/knowMore/OilField";
import EngPlastics from "../src/knowMore/EngPlastics";
import HybridMotor from "./knowMore/HybridMotor";
import Hybrid from "../src/pages/Hybrid";
import Scooter from "./pages/Scooter";

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (

    <div className="App">
      {
        loading ?
          <div className="preloader">
            <img className="loadingImg" src={logo} alt='loading...' />
          </div>
          :
          <Router basename="/">
            <Navbar />
            <Routes>
              <Route path="/" element={<Navigate to="/home"/>} />
              <Route path="/home" element={<Home />} />
              <Route path="/hybrid" element={<Hybrid />} />
              <Route path="/scooter" element={<Scooter />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/EvSpeedbike" element={<EvSpeedBike />} />
              <Route path="/aerospace" element={<Aerospace />} />
              <Route path="/CadCam" element={<CadCam />} />
              <Route path="/PolyCast" element={<PolyCast />} />
              <Route path="/IMLtooling" element={<IMLtooling />} />
              <Route path="/news" element={<News />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/gaskets" element={<Gaskets />} />
              <Route path="/MechSeal" element={<MechSeal />} />
              <Route path="/OilField" element={<OilField />} />
              <Route path="/EngPlastics" element={<EngPlastics />} />
              <Route path="/HybridMotor" element={<HybridMotor />} />
            </Routes>
          </Router>}
    </div>
  );
};

export default App;
