import React from 'react';
import lowSpeedScooterWhite from '../assets/lspeedWhite.jpg';
import lowSpeedScooterBlue from '../assets/lspeedDarkBlue.jpg';
import lowSpeedScooterRed from '../assets/lspeedRed.jpg';
import "../pages/Scooter.css";
import Footer from "../components/Footer";


function Scooter() {
    return (
        <section className='ScooterContainer' id='electricScooter'>


            <div className='flex'>
                <div className='scooterDisplay'>
                    <img className='electricScooter' id='redElectricScooter' src={lowSpeedScooterRed} alt='banner' />
                    <img className='electricScooter' id='blueElectricScooter' src={lowSpeedScooterBlue} alt='banner' />
                    <img className='electricScooter' id='whiteElectricScooter' src={lowSpeedScooterWhite} alt='banner' />
                </div>

                <div className='varientsbtnContainer'>
                    <strong>&#10026; LMAX-Scott scooter<br />
                        &#10026; 500 Watts Motor<br />
                        &#10026; 2KWH Battery Pack<br />
                        &#10026; 90km Range/Charge<br />
                    </strong>
                    <a href='#redElectricScooter' className='varientBtn' >Solid Red</a>
                    <a href='#blueElectricScooter' className='varientBtn' >Dark Blue</a>
                    <a href='#whiteElectricScooter' className='varientBtn' >Sirius White</a>
                </div>
            </div>

            <Footer />
        </section>

    )
}

export default Scooter