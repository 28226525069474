import React from 'react';
import '../knowMore/Aerospace.css';
import jigsAndfiximg from '../images/jigs-fixtures.jpg';


function Aerospace() {
    return (
        <div className='aerospaceContainer'>
            <div className='blank'></div>
            <div className='aeroDetails'>DESIGN AND DEVELOPMENT OF JIGS AND FIXTURES_AEROSPACE MRO'S</div>
            <div className='aeroDetailsP'>Jigs and Fixtureing for cold and hot end of engine segments.Assembly
                and Inspection jigs for nacelle also bond jigs for composites</div>

            <div className='floatRight'>
                <div className='floatRightDiv'>&#10162;  CNC Precision Machining</div>
                <div className='floatRightDiv'>&#10162;  Jigs And Fixtures</div>
                <div className='floatRightDiv'>&#10162;  Press Tools - Manufacture</div>
                <div className='floatRightDiv'>&#10162;  Tooling Technologies and Materials</div>
                <div className='floatRightDiv'>&#10162;  Miscellaneous</div>
            </div>

            <img src={jigsAndfiximg} className='aeroImg' alt='banner' />

        </div>
    );
};

export default Aerospace;