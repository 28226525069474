import React from 'react';
import '../knowMore/EvspeedBike.css';
import head from "../assets/evSpeedBikeH.png";
import front from "../assets/evSpeedBikeF.png";
import side from "../assets/evSpeedBikeS.png";
import back from "../assets/evSpeedbikeB.png";

function EvSpeedBike() {

    return (
        <div className='speedBikeContainer'>

            <div className='speedBikeimgContainer'>

                <div className='title'><strong>BOSON ELECTRIC </strong>
                    <span className='subTitle'><strong>UNIT OF MAN ENGINEERED SYSTEMS</strong></span></div>


                <div className='sides'>
                    <a href="#front" className='sidesLink'>Front</a>
                    <a href="#head" className='sidesLink'>Lights</a>
                    <a href="#side" className='sidesLink'>Side</a>
                    <a href="#back" className='sidesLink'>Back</a>
                </div>

                <div className='features'>
                    <div className='featureslist'><strong>Max speed<div>120Kms</div></strong></div>
                    <div className='featureslist'><strong>Battery range<div>150kmh/charge</div></strong></div>
                    <div className='featureslist'><strong>Full charging time<div>1hr 20mins only</div></strong></div>
                    <div className='featureslist'><strong>Battery Pack Warrenty<div>5 Years</div></strong></div>
                    <div className='featureslist'><strong>Battery Pack starts<div>with 4.9kwh</div></strong></div>
                    <div className='featureslist'><strong>Charger<div>20 AMP Charger provided</div></strong></div>
                    <div className='featureslist'><strong>Motor<div>6KW Constant/10KW Peek Power</div></strong></div>

                </div>
                <img className='speedBikeimgContainer' id="front" src={front} alt='banner' />

                <div className='speedBikeimgContainer'>
                    <img className='speedBikeimg' id="head" src={head} alt='banner' />
                </div>

                <div className='speedBikeimgContainer'>
                    <img className='speedBikeimg' id="side" src={side} alt='banner' />
                </div>
                <div className='speedBikeimgContainer'>
                    <img className='speedBikeimg' id="back" src={back} alt='banner' />
                </div>
            </div>
        </div>
    );
};

export default EvSpeedBike;