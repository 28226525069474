import React from 'react';
import '../pages/Home.css';
import icon from '../images/videoicn.jpg';
import evSpeedBikeSide from '../assets/evSpeedBikeS.png';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div className='home' id='home'>
      <section className='homeContainer' id='evSpeedBike'>
        <a className='trailerBtn'
          href='https://www.facebook.com/100088290561055/posts/110069565279395/?app=fbl'
          target='_blank'
          rel='noreferrer'>Watch trailer_<img className='videoIcn' src={icon} alt='icon' /> </a>
        <Link className='knowmore' to="/EvSpeedBike">Know more</Link>
        <img src={evSpeedBikeSide} className='evSpeedBike' alt='banner' />
      </section>

    </div >
  );
};

export default Home;