import React from 'react';
import '../components/Footer.css';
import watsapp from '../assets/watsapp.png';
import insta from '../assets/insta.png';
import facebook from '../assets/fb.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer'>
            <div className='footerContainer'>
                <div className='footerBar'>
                    <Link to='/home' className='footerLinks'>Home recall</Link>
                    <Link to='contact' className='footerLinks'>Contact</Link>
                    <Link to="/careers" className='footerLinks'>Careers</Link>
                    <Link to="/news" className='footerLinks'>News</Link>

                </div>

                <div className='logoContainer'>

                    <a href="https://facebook.com/profile.php?id=100088290561055" target="_blank" rel='noreferrer'>
                        <img src={facebook} alt='banner' className='LogoFb'></img></a>
                    <a href="https://www.instagram.com/tv/Che-o7UFIMF/?igshid=YmMyMTA2M2Y=" target="_blank" rel='noreferrer'>
                        <img src={insta} alt='banner' className='LogoInsta'></img></a>
                    <a href="https://wa.me/=917010138956" target="_blank" rel='noreferrer'>
                        <img src={watsapp} alt='banner' className='LogoWatsapp'></img></a>

                </div>
                <div className='footerLogoContainer'>
                    <div
                        style={{ color: "grey", fontFamily: "sans-serif" }}>
                        <strong>Man engineered systems</strong><b>&#169;2022</b></div>
                </div>

            </div>
        </div>

    );
};

export default Footer;
