import React from 'react';
import Footer from '../components/Footer';
import '../pages/Contact.css';
import backgroundImg from '../assets/manengsys-logo.png';


function Contact() {

  function sendMail() {
    window.open("mailto:sales@maneng.in");
  };

  return (
    <div className='contact' id='contact'>
      <div className='blank'></div>
      <div className='contactContainer'>
        <img src={backgroundImg} alt='banner' className='backgroundImg' />

        <h1 className='contactHeader'>CONTACT US
          <span className='icon'> &#128241;<sup>&#8520;&#8520;&#8520;</sup></span></h1>
        <span>

          <h3 className='contactDetails'>HEADOFFICE:</h3>
          <h3 className='contactDetails'>Address:</h3>
          <h4 className='contactDetails'>3-55/13,Packiya nagar Keezha Vilancadu, </h4>
          <h4 className='contactDetails'>Thittuvilai,</h4>
          <h4 className='contactDetails'>KK District 629852,</h4>
          <h4 className='contactDetails'>TamilNadu</h4>

          <h4 className='contactDetails'>INDIA:</h4>
          <h4 className='contactDetails'>&#128241; Sheik Abdullah.M.F - +91 7010138956</h4>

          <button className='mailButton' onClick={sendMail}> &#128231; sales@maneng.in (click to mail)</button>
          <h4 className='contactDetails'>&#127760; www.maneng.in</h4>

        </span>

      </div>
      <Footer />

    </div>
  );
};

export default Contact;