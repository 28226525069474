import React from 'react';
import '../knowMore/CadCam.css';

function CadCam() {
  return (
    <div className='cadContainer'>
      <div className='blank'></div>
      <div className='cadHeader'>Design and Development</div>
      <div className='cadDetails'>We are committed to deliver 2D Cad drawings as per requested standards as well as 3D models
        as per your concept. Undertaking 2D to 3D conversions. Compare scan image and analysis of product
        against its nominal data. Precision machining consultation and services available.

        We do undertake and create 3D models and tool design using Cad tools especially
        Unigraphics for Injection molding/thermo forming/aluminum castings and so on.
      </div>

    </div>

  );
};

export default CadCam;