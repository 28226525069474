import React from 'react';
import '../pages/About.css';
import backgroundImg from '../assets/manengsys-logo.png';
import { Link } from 'react-router-dom';
import Footer from "../components/Footer"

function About() {

  return (
    <div className='about'>
      <div className='blank'></div>
      <div className='aboutContainer'>

        <img src={backgroundImg} alt='banner' className='backgroundImg' />

        <div className='aboutDetails'>

          <h2 style={{ textDecoration: "underline" }}> <strong>About Man Engineered Systems</strong></h2>

          We work with many clients worldwide on many engineering services on various fields and to provide better
          product with friendly financial options.
          We are committed to deliver engineering products and equipments as per requested standards and as well as 2D,3D models as per your concept.
          We are the manufacturer and supplier of cost effective and quality engineering services.

          <p style={{ textDecoration: "underline" }}>Our main products and services below </p>

          <h5><strong>EV SPORTS BIKE</strong></h5>

          Our very first electric speed bike prototype, launched on 10th NOV 2021 as our contribution to "make in india" initiation.
          Our EV speed bike, proudly named after scientist Thiru. Satyendra Nath Bose, on his contribution
          to the discovery of God's particle. <Link to='/'>know more </Link>

          <h5><strong>HYBRID SCOOTER</strong></h5>
          Convert any of your fuel Scooter into <strong>Hybrid Scooter</strong>
          (works with petrol and electric alternatively)
          or to a Electric Scooter with Man Engineered Hybrid Kit / Convertion Kit
          Delivering huge cost savings and stress free performance.

          The Hybrid Kit comes with A Battery Pack and A Electric Motor and A Controller

          <h5><strong>LMAX-SCOTT ELECTRIC SCOOTER</strong></h5>
          &#10026; 500 Watts Motor<br />
          &#10026; 2KWH Battery Pack<br />
          &#10026; 90km Range/Charge<br />

          <h5><strong>POLYURETHANE CASTINGS</strong></h5>

          Polyurethane is a polymer compound with many versatile uses. It is employed in the marine, fuel,
          and manufacturing industries, among countless others. One of the main factors in polyurethane’s
          versatility is that it exists as a liquid before molding and casting,
          Since polyurethane is such a versatile material, it can be molded and cast in a number of different
          ways to suit individual or industry needs. Some of the most common methods are discussed below.
          <Link to='/PolyCast'>know more </Link>

          <h5><strong>AEROSPACE MRO_TOOL DESIGN AND MANUFACTURING SUPPORT</strong></h5>

          Jigs and Fixtureing for Cold and Hot end of engine segments.
          Assembly and Inspection Jigs for Nacelle also Bond Jigs for Composites.
          <Link to='/Aerospace'>know more</Link>

          <h5><strong>OIL FIELD</strong></h5>
          We work with many clients worldwide to provide niche solutions, to provide better
          drilling tools/equipment at most affordable or friendly financial options.
          <Link to='/OilField'> know more</Link>


          <h5><strong>MECHANICAL SEALS</strong></h5>
          All Standards and non-standard dimensions Of corrugated metal graphite gasket manufacturing.
          are available.<Link to='/MechSeal'> know more</Link>


          <h5><strong>OTHER ENGINEERING SERVICES</strong></h5>
          We work with many clients worldwide to provide niche solutions, to provide better
          drilling tools/equipment at most affordable or friendly financial options.
          <Link to='/Services'> know more</Link>

          <h5><strong>CAD/CAM/PRECISION</strong></h5>
          We are committed to deliver 2D Cad drawings as per requested standards as well as 3D models as per your concept.
          We are the manufacturer and supplier of cost effective and quality engineering services.
          <Link to='/CadCam'> know more</Link>

          <h5><strong>IML/TOOLING  </strong></h5>
          In Mold Labelling Design and Development for Injection Molding Industrial/Automotive Component.
          <Link to='/IMLtooling'> know more</Link>

          <h5><strong>RUBBER AND SILICONE GASKETS</strong></h5>
          We are the manufacturer and supplier of cost effective and quality Rubber and Silicone.
          <Link to='/Gaskets'> know more</Link>

          <h5><strong>ENGINEERING PLASTICS</strong></h5>
          We are the manufacturer and supplier of cost effective and quality Engineering Plastic.
          <Link to='/EngPlastics'> know more</Link>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;