import React from 'react';

function News() {
  return (
    <div className='newsContainer'>
      <div className='blank'></div>
      <h2 style={{ textDecoration: "underline" }}>NEWS</h2>

      <h3>EV SPEED BIKE</h3>
      Our very first electric speed bike prototype,
      launched on 10th NOV 2021 as our contribution to
      "make in india" initiation. Our EV speed bike,
      named after scientist Thiru. Satyendra Nath Bose,
      on his contribution to the discovery of God's particle.

    </div>
  );
};

export default News;