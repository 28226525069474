import React from 'react';
import '../knowMore/Oilfield.css';
import ENWpdf from '../documents/Equip - thruster area.pdf';
import ENWApdf from '../documents/equip-new-warehouse-area.pdf';
import ER13pdf from '../documents/equip-rack-1-13.pdf';
import ER1pdf from '../documents/equip-rack-1.pdf';
import ER2pdf from '../documents/equip-rack-2.pdf';
import ER4pdf from '../documents/equip-rack-4-2.pdf';
import ER4Fpdf from '../documents/equip-rack-4-floo1.pdf';
import pdfIcn from '../images/pdfIcn.jfif';


function OilField() {

    function openENW() {
        window.open(ENWpdf);
    };

    function openENWA() {
        window.open(ENWApdf);
    };

    function openER13() {
        window.open(ER13pdf);
    };

    function openER1() {
        window.open(ER1pdf);
    };

    function openER2() {
        window.open(ER2pdf);
    };

    function openER4() {
        window.open(ER4pdf);
    };

    function openER4F() {
        window.open(ER4Fpdf);
    };

    return (
        <div
            className='oilfieldContainer'>
            <div className='blank'></div>
            <h2>OILFIELD</h2>
            <p>We work with many clients worldwide to provide niche solutions,
                to provide better drilling tools/equipment at most affordable or friendly financial options.</p>

            <div className='imgBtnContainer'>

                <button className='imgBtn' onClick={openENW}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-thruster-area</p>
                </button>

                <button className='imgBtn' onClick={openENWA}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-warehouse-area</p>
                </button>

                <button className='imgBtn' onClick={openER13}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-rack-1-13</p>
                </button>

                <button className='imgBtn' onClick={openER1}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-rack-1</p>
                </button>

                <button className='imgBtn' onClick={openER2}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-rack-2</p>
                </button>

                <button className='imgBtn' onClick={openER4}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-rack-4-2</p>
                </button>

                <button className='imgBtn' onClick={openER4F}>
                    <img src={pdfIcn} alt='icn' className='gasketsImg' />
                    <p>Equip-rack-4-floo1</p>
                </button>
            </div>
        </div>
    );
};

export default OilField;