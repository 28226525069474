import React from 'react';
import '../knowMore/IMLtooling.css';
import toolingimg from '../images/mould-design.jpg';
import moldFile from '../documents/maneng-molding.pdf';
import designConcept from '../documents/mould-design-concept.pdf';

function IMLtooling() {
    function documentpdf() {
        window.open(moldFile);
    };

    function designpdf() {
        window.open(designConcept);
    };
    return (
        <div className='imlToolingContainer' >
            <div className='blank'></div>
            <h2 className='toolingHeader'>INJECTION MOLD </h2>

            In Mold Labelling Design and Development for Injection Molding Industrial/Automotive Components.

            <div>
                <button className='pdfButton' onClick={documentpdf}>CLICK TO SEE MAN-ENG MOLDING</button>
                <button className='pdfButton' onClick={designpdf}>CLICK TO SEE OUR MOLDING DESIGN CONCEPT</button>
            </div>


            <img src={toolingimg} alt='banner' className='toolingDesignImg' />

        </div>
    );
};

export default IMLtooling;