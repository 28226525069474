import React from 'react';
import '../knowMore/HybridMotor.css';
import controller from '../assets/controller.jpg';
import keySwitch from '../assets/keySwitch.jpg';
import hybridkit from '../assets/hybridKit.jpg';
import hubmotor from '../assets/hubMotor.jpg';
import holders from '../assets/holders.jpg';

function HybridMotor() {
  return (
    <div className='hybrid'>
      <div className='blank'></div>
      <div className='hybridkitContainer'>

        <div className='imgContainer'>
          <img src={hybridkit} alt='banner' className='kitImg'></img>
          <span className='hybridTitle'>
            <strong>HYBRID KIT</strong><br/>
            Convert any of your fuel scooter into a <strong>Hybrid vehicle </strong>
            or a fully electric functional vehicle using <strong>Man Engineered Systems hybrid kit.</strong><br />
            The Hybrid kit includes...<br />
            Electric Motor<br />
            3 years warrenty battery pack<br /> and a sinewave controller</span>
        </div>
        <div className='imgContainer'>
          <img src={hubmotor} alt='banner' className='motorImg'></img>
          <span className='hybridTitle'>
            <strong>ELECTRIC HUB MOTOR</strong><br/>
            2KW electric hub brushless motor for better durablity.</span>
        </div>
        <div className='imgContainer'>
          <img src={keySwitch} alt='banner' className='keyswitchImg'></img>
          <span className='hybridTitle'>
            <strong>Keyswitch</strong><br/>
            A separate keyswitch will be provided for the Electric start.
          </span>
        </div>
        <div className='imgContainer'>
          <img src={controller} alt='banner' className='controllerImg'></img>
          <span className='hybridTitle'>
            <strong>Sinewave controller</strong><br/>
            The controller will be responsible for the total working of the appartatus.
          </span>
        </div>
        <div className='imgContainer'>
          <img src={holders} alt='banner' className='holderImg'></img>
          <span className='hybridTitle'>All these bolts and holders will be used for fixing purpose.</span>
        </div>
      </div>
    </div>
  );
};

export default HybridMotor;