import React from 'react';
import '../knowMore/Gaskets.css';
import pdfIcn from '../images/pdfIcn.jfif';
import MGGpdf from '../documents/Corrugated-Metal-Graphite-Gasket.pdf';
import DJGpdf from '../documents/Double-Jacketed-Gasket.pdf';
import KPGpdf from '../documents/Kammprofile-Gaskets.pdf';
import RJGpdf from '../documents/Ring-Joint-Gaskets.pdf';
import RGSpdf from '../documents/Rubber-Gaskets-Sheets.pdf';
import SWGpdf from '../documents/Spiral-Wound-Gaskets.pdf';
import SEGpdf from '../documents/Spring-Energized-Gasket.pdf';

function Gaskets() {

  function forMGGpdf() {
    window.open(MGGpdf);
  };

  function forDJGpdf() {
    window.open(DJGpdf);
  };

  function forKPGpdf() {
    window.open(KPGpdf);
  };

  function forRJGpdf() {
    window.open(RJGpdf);
  };

  function forRGSpdf() {
    window.open(RGSpdf);
  };

  function forSWGpdf() {
    window.open(SWGpdf);
  };

  function forSEGpdf() {
    window.open(SEGpdf);
  };

  return (
    <div className='gasketsContainer'>
      <div className='blank'></div>
      <h2>Rubber and Silicone Gaskets</h2>
      We are the manufacturer and supplier of cost effective and
      quality Rubber and Silicone and quality Gasket products.

      <div className='gasketsBtn'>
        <button className='imgBtn' onClick={forMGGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Corrugated-Metal-Graphite-Gasket</p>
        </button>

        <button className='imgBtn' onClick={forDJGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Double-Jacketed-Gasket</p>
        </button>

        <button className='imgBtn' onClick={forKPGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Kammprofile-Gaskets</p>
        </button>
        <button className='imgBtn' onClick={forRJGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Ring-Joint-Gaskets</p></button>
        <button className='imgBtn' onClick={forRGSpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Rubber-Gaskets-Sheets</p>
        </button>
        <button className='imgBtn' onClick={forSWGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Spiral-Wound-Gaskets</p>
        </button>
        <button className='imgBtn' onClick={forSEGpdf}>
          <img className='gasketsImg' src={pdfIcn} alt='banner' />
          <p>Spring-Energized-Gasket</p>
        </button>
      </div>
    </div>
  );
};

export default Gaskets;