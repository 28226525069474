import React from 'react';
import productImg from '../images/maneng-product.jpg';
import '../knowMore/EngPlastics.css';

function EngPlastics() {
  return (
    <div className='plasticContainer'>

      <div className='blank'></div>
      <div className='engPlastics'>
        <h2>ENGINEERING PLASTICS</h2>
        We are the manufacturer and supplier of cost effective and quality plastics.
      </div>
      <div className='plasticImgContainer'>
        <img src={productImg} alt='banner' className='plasticImg' />
      </div>
    </div>
  );
};

export default EngPlastics;