import React from 'react';
import '../components/Navbar.css';
import Logo from '../assets/manengsys-logo.png';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div className='navbar'>

      <div className='navbarContainer'>
        <img className='navbarLogo' src={Logo} alt='banner'></img>

        <div className='right'>

          <Link className='navbarLinks' to="/home">Home</Link>
          <Link className='navbarLinks' to="/services">Services</Link>
          <Link className='navbarLinks' to="/contact">Contact Us</Link>
          <Link className='navbarLinks' to="/about">About Us</Link>

        </div>

        <div className='header'>
          <Link className='headerLinks' to="/home">Ev Speed Bike</Link>
          <Link className='headerLinks' to="/hybrid">Hybrid Scooter</Link>
          <Link className='headerLinks' to="/scooter">Electric Scooter</Link>

        </div>


      </div>
    </div>
  );
};

export default Navbar;