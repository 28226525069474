import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/Services.css';
import icon from '../images/settingsIcon.jfif';
import left from '../images/cd-img-3.png';
import right from '../images/cd-img-2.png';
import backgroundImg from '../assets/manengsys-logo.png';


function Services() {
  return (
    <div className='services'>

      <div className='blank'></div>

      <div className='servicesContainer'>
      <img src={backgroundImg} alt='banner' className='backgroundImg' />


        <div className='ourServices'>OUR SERVICES <img className='settingIcon' src={icon} alt='banner' /></div>

        <div className='engServices'>
          <img className='leftimg' src={left} alt='banner' />
          <img className='rightimg' src={right} alt='banner' />

          <div className='servicesLinksDiv'>

            <span style={{paddingTop:"10px"}}>
              We do undertake and create 3D models and tool design
              using Cad tools especially Unigraphics for Injection molding/thermo forming/aluminum castings
              and so on. In mold labelling (IML)design and development will be undertake.
            </span>

            <Link to='/aerospace' className='servicesLinks' >&#10162; Aerospace MRO-tool design and manufacturing</Link>
            <Link to='/CadCam' className='servicesLinks' >&#10162; Design and developement CAD/CAM/CAE</Link>
            <Link to='/PolyCast' className='servicesLinks' >&#10162; Polyurethane castings</Link>
            <Link to='/IMLtooling' className='servicesLinks' >&#10162; IML Tooling</Link>
            <Link to='/gaskets' className='servicesLinks' >&#10162; Rubber and silicon gaskets</Link>
            <Link to='/MechSeal' className='servicesLinks' >&#10162; Mechanical seals </Link>
            <Link to='/OilField' className='servicesLinks' >&#10162; Oil field </Link>
            <Link to='/EngPlastics' className='servicesLinks' >&#10162; Engineering plastics</Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Services;